<template>
  <v-container>
    <v-row class="text-left mb-10">
      <v-col cols="12" sm="12">
        <v-form ref="mailer">
          <v-autocomplete
            v-model="selectedStudent"
            :items="students"
            :search-input.sync="searchStudents"
            label="Étudiant *"
            outlined
            dense
            @input="clearsearchStudents"
            no-data-text="Tapez le nom d'une personne..."
            class="mt-5"
          />
          <div v-if="selectedStudent">
            <v-select
              outlined
              dense
              label="Modèle de mail"
              :items="emails"
              item-text="title"
              :item-value="(item) => item"
              v-model="selectedEmail"
            ></v-select>
            <v-text-field
              v-if="selectedEmail"
              label="À"
              outlined
              dense
              v-model="selectedStudent.emailPrive"
              disabled
            ></v-text-field>
            <v-text-field
              v-if="selectedEmail"
              label="Objet"
              outlined
              dense
              v-model="editedEmail.subject"
            ></v-text-field>
            <tiptap-vuetify
              v-model="editedEmail.html"
              :extensions="extensions"
              v-if="selectedEmail"
            />
            <v-btn
              outlined
              dense
              block
              v-if="selectedEmail && !sendDialog"
              @click="sendDialog = true"
              class="mt-5"
              >Envoyer le mail</v-btn
            >
            <v-btn
              outlined
              dense
              block
              v-if="selectedEmail && sendDialog"
              @click="sendEmail(selectedStudent, editedEmail)"
              class="mt-5"
              >Confirmer</v-btn
            >
          </div></v-form
        ></v-col
      >
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  async mounted() {
    const { data: emails } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/emails`,
    });
    this.emails = emails;
  },
  components: { TiptapVuetify },
  data: () => ({
    snackbar: { visible: false, text: "", color: "" },
    students: [],
    searchStudents: null,
    selectedStudent: null,
    sendDialog: false,
    selectedEmail: null,
    emails: [],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  watch: {
    searchStudents: _.debounce(function (val) {
      val && val !== this.selectedStudent && this.queryPicker(val, "students");
    }, 300),
    selectedEmail(val) {
      if (val) {
        this.editedEmail = Object.assign({}, val);
        this.editedEmail.html = this.editedEmail.html.replaceAll(
          "${Nom}",
          this.selectedStudent.lastName
        );
        this.editedEmail.html = this.editedEmail.html.replaceAll(
          "${Prenom}",
          this.selectedStudent.firstName
        );
        this.editedEmail.html = this.editedEmail.html.replaceAll(
          "${emailEcole}",
          this.selectedStudent.emailEcole
        );
      }
    },
    selectedStudent() {
      this.selectedEmail = null;
    },
  },
  methods: {
    async sendEmail(student, email) {
      try {
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/admin/notifications`,
          data: { student, email },
        });
        this.searchStudents = null;
        this.selectedStudent = null;
        this.sendDialog = false;
        this.selectedEmail = null;
        this.snackbar = {
          visible: true,
          text: "E-mail envoyé.",
          color: "success",
        };
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          text: "Erreur d'envoi.",
          color: "error",
        };
      }
    },
    async queryPicker(v, key) {
      this.loading = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/students?search=${v}`,
      });
      contacts.map((c) =>
        this[key].push({
          text: `${c.firstName} ${c.lastName} (${c.filiere})`,
          value: c,
        })
      );
      this[key] = _.uniqBy(this[key], "value.idisa");
      this.loading = false;
    },
    clearsearchStudents() {
      this.searchStudents = null;
    },
  },
};
</script>

