var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.accounts)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mt-10",attrs:{"outlined":"","type":"info","border":"left"}},[_vm._v(" Téléchargez le "),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadReport.apply(null, arguments)}}},[_vm._v("rapport")]),_vm._v(" complet des notifications envoyées. ")])],1)],1),_c('v-row',{staticClass:"text-left mb-10"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Rechercher un compte..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',[_c('v-list',{attrs:{"two-line":""}},[(_vm.accounts && !_vm.accounts.length)?_c('v-list-item-group',[_c('v-list-item',[_vm._v("Pas de résultats...")])],1):_c('v-list-item-group',[_vm._l((_vm.accounts),function(account,index){return [_c('v-list-item',{key:account._id,on:{"click":function($event){return _vm.selectAccount(account)}}},[[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(account.fullname)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(account.notifications[0].subject)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                        account.notifications[0].html
                          .replace(/<p>/gi, '')
                          .replace(/<\/p>/gi, ' ')
                          .substr(0, 50)
                      )}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(
                        ("Création compte : " + (_vm.format(
                          _vm.parseISO(account.accountCreation),
                          'dd.MM.yyyy'
                        )))
                      )}}),_c('v-badge',{staticClass:"mr-4 mb-2",attrs:{"content":account.notifications.length}})],1)]],2),(index < _vm.accounts.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1),_c('div',{staticClass:"text-center mt-5"},[(_vm.accounts.length && _vm.totalPages > 1)?_c('v-pagination',{attrs:{"length":_vm.totalPages},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e()],1)],1),(_vm.selectedAccount)?_c('v-col',_vm._l((_vm.sortedNotifications),function(notification){return _c('v-card',{key:notification.messageId,staticClass:"pa-10 mb-10"},[_c('v-card-subtitle',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[(!notification.from)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" rentree.academique@heig-vd.ch <rentree.academique@heig-vd.ch> ")]):_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(notification.from)+" ")]),_c('div',[_vm._v(_vm._s(notification.subject))]),(typeof notification.to === 'string')?_c('div',[_vm._v(" À : "),_c('a',{attrs:{"href":("mailto:" + (notification.to))}},[_vm._v(_vm._s(notification.to))])]):_vm._e(),(typeof notification.to === 'object')?_c('div',[_vm._v(" À : "),_vm._l((notification.to),function(n,index){return _c('span',{key:n},[_c('a',{attrs:{"href":("mailto:" + n)}},[_vm._v(_vm._s(n))]),(index < notification.to.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})],2):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('div',[_vm._v(" "+_vm._s(_vm.format( _vm.parseISO(notification.date), "dd MMMM yyyy à HH:mm", { locale: _vm.fr } ))+" ")]),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadPdf(_vm.selectedAccount._id, notification.messageId)}}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)],1)])],1)],1)],1),_c('v-divider'),_c('v-card-text',{domProps:{"innerHTML":_vm._s(notification.html)}})],1)}),1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }