<template>
  <admin-part />
</template>

<script>
import AdminPart from "@/components/AdminPart.vue";
export default {
  components: {
    AdminPart,
  },
};
</script>

