<template>
  <v-container v-if="accounts">
    <v-row>
      <v-col cols="12">
        <v-alert outlined type="info" border="left" class="mt-10">
          Téléchargez le
          <a href @click.prevent="downloadReport">rapport</a> complet des
          notifications envoyées.
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="text-left mb-10">
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          placeholder="Rechercher un compte..."
          v-model="search"
        ></v-text-field>
        <v-card>
          <v-list two-line>
            <v-list-item-group v-if="accounts && !accounts.length"
              ><v-list-item>Pas de résultats...</v-list-item></v-list-item-group
            >
            <v-list-item-group v-else>
              <template v-for="(account, index) in accounts">
                <v-list-item :key="account._id" @click="selectAccount(account)">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="account.fullname"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="account.notifications[0].subject"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-text="
                          account.notifications[0].html
                            .replace(/<p>/gi, '')
                            .replace(/<\/p>/gi, ' ')
                            .substr(0, 50)
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text
                        v-text="
                          `Création compte : ${format(
                            parseISO(account.accountCreation),
                            'dd.MM.yyyy'
                          )}`
                        "
                      ></v-list-item-action-text>

                      <v-badge
                        class="mr-4 mb-2"
                        :content="account.notifications.length"
                      ></v-badge>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < accounts.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
        <div class="text-center mt-5">
          <v-pagination
            v-model="options.page"
            :length="totalPages"
            v-if="accounts.length && totalPages > 1"
          ></v-pagination>
        </div>
      </v-col>
      <v-col v-if="selectedAccount">
        <v-card
          v-for="notification in sortedNotifications"
          :key="notification.messageId"
          class="pa-10 mb-10"
        >
          <v-card-subtitle>
            <v-container class="pa-0">
              <v-row>
                <v-col>
                  <div v-if="!notification.from" class="font-weight-bold">
                    rentree.academique@heig-vd.ch
                    &lt;rentree.academique@heig-vd.ch&gt;
                  </div>
                  <div v-else class="font-weight-bold">
                    {{ notification.from }}
                  </div>
                  <div>{{ notification.subject }}</div>
                  <div v-if="typeof notification.to === 'string'">
                    À :
                    <a :href="`mailto:${notification.to}`">{{
                      notification.to
                    }}</a>
                  </div>
                  <div v-if="typeof notification.to === 'object'">
                    À :
                    <span v-for="(n, index) in notification.to" :key="n"
                      ><a :href="`mailto:${n}`">{{ n }}</a
                      ><span v-if="index < notification.to.length - 1">, </span>
                    </span>
                  </div>
                </v-col>
                <v-col class="text-right"
                  ><div>
                    {{
                      format(
                        parseISO(notification.date),
                        "dd MMMM yyyy à HH:mm",
                        { locale: fr }
                      )
                    }}
                  </div>
                  <div>
                    <v-btn
                      icon
                      @click="
                        downloadPdf(selectedAccount._id, notification.messageId)
                      "
                      ><v-icon>mdi-cloud-download</v-icon></v-btn
                    >
                  </div></v-col
                >
              </v-row>
            </v-container>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text v-html="notification.html"></v-card-text> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO } from "date-fns";
import fr from "date-fns/locale/fr";
import { debounce } from "lodash";
import fileDownload from "js-file-download";

export default {
  name: "AdminPart",
  async mounted() {
    const { items, total, pages } = await this.getDataFromApi();
    this.accounts = items;
    this.total = total;
    this.totalPages = pages;
  },
  data: () => ({
    accounts: undefined,
    selectedAccount: undefined,
    options: { page: 1 },
    search: "",
    total: 0,
    totalPages: 0,
    loading: false,
    parseISO,
    format,
    fr,
  }),
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { page } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/notifications?page=${page}&search=${this.search}&itemsPerPage=10`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      const pages = data.totalPages;
      return {
        items,
        total,
        pages,
      };
    },
    selectAccount(account) {
      this.selectedAccount = account;
    },
    async downloadPdf(_id, index) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/notifications/${_id}?messageId=${index}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "compte.pdf");
    },
    async downloadReport() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/notifications?format=xlsx`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "notifications.xlsx");
    },
  },
  computed: {
    sortedNotifications() {
      const notifications = this.selectedAccount.notifications;
      return notifications.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
  },
  watch: {
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.accounts = data.items;
        this.total = data.total;
        this.totalPages = data.pages;
      },
      deep: true,
    },
    search: debounce(async function () {
      this.options.page = null;
      this.options.page = 1;
    }, 500),
  },
};
</script>
