<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" overlay-opacity="0">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar tile>
            <img
              src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>ACCOUNT MAILER</v-list-item-title>
            <v-list-item-subtitle>Interface de gestion</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="outbox" exact>
          <v-list-item-icon>
            <v-icon>mdi-email-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Historique d'envoi</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="mailer" exact>
          <v-list-item-icon>
            <v-icon>mdi-email-fast-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Envoi communication</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
export default {
  name: "App",
  data: () => ({ drawer: null }),
  components: {
    UserProfile,
  },
};
</script>