import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import router from "./router";

Vue.config.productionTip = false;

const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const init = { onLoad: "login-required" };
if (!isChrome) {
  init.checkLoginIframe = false;
}

Vue.use(VueKeyCloak, {
  init,
  config: {
    realm: process.env.VUE_APP_KC_REALM,
    url: process.env.VUE_APP_KC_URL,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
  },
  onReady: (keycloak) => {
    if (!keycloak.hasResourceRole("admin", process.env.VUE_APP_KC_CLIENT_ID)) {
      keycloak.logout();
    } else {
      tokenInterceptor();
      new Vue({
        vuetify,
        router,
        render: (h) => h(App),
      }).$mount("#app");
    }
  },
});
