import Vue from "vue";
import VueRouter from "vue-router";
import Outbox from "@/views/Outbox.vue";
import Mailer from "@/views/Mailer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/outbox",
  },
  {
    path: "/outbox",
    name: "Outbox",
    component: Outbox,
  },
  {
    path: "/mailer",
    name: "Mailer",
    component: Mailer,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
